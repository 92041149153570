import { useQuery } from "@tanstack/react-query"
import { useUserToken } from "../token/TokenQuery"
import { GetBackendClient } from "../../services/BackendClientFactory"

export const betaInfoQueryKey = "beta-info"
export const betaUserStatusKey = "user-beta-status"


export const useBetaInfoQuery = () => {

    const { userToken, isAuthenticated } = useUserToken()

    const { data } = useQuery({
        queryKey: [betaInfoQueryKey],
        enabled: isAuthenticated,
        queryFn: async () => {
            const resp = await GetBackendClient(userToken).getBetaInfo.getBetaInfo()

            return resp.data
        }
    })

    return { info: data }
}

export const useUserBetaStatusQuery = () => {

    const { userToken, isAuthenticated } = useUserToken()

    const { data } = useQuery({
        queryKey: [betaUserStatusKey],
        enabled: isAuthenticated,
        queryFn: async () => {
            const resp = await GetBackendClient(userToken).getUserBetaQueueStatus.getUserBetaQueueStatus()

            return resp.data
        },
        staleTime: 0
    })

    return { userBetaStatus: data }
}