import { FC } from "react"
import { Spinner } from "react-bootstrap"
import { useTaleImageQuery } from "../stores/image/ImageQueries"
import { useCreateTaleImageMutator, useSetTaleImageMutator } from "../stores/image/ImageMutators"
import { TaleIdResource } from "../generated/BackendApiClient"
import { CostingButton } from "./CostingButton"
import { UploadButton } from "./UploadButton"


interface TaleImageProps {
    taleId: TaleIdResource
    visualDescription: string
}

export const TaleImageFrame: FC<TaleImageProps> = ({ taleId, visualDescription }) => {

    const { taleImage } = useTaleImageQuery(taleId, visualDescription)
    const { createTaleImageMutator, creatingImage } = useCreateTaleImageMutator()
    const { setTaleImageMutator, settingImage } = useSetTaleImageMutator()

    const generateNewImage = async () => await createTaleImageMutator.mutateAsync({ taleId, visualDescription })
    const setUploadedImage = async (imageBase64: string, mimeType: string) => await setTaleImageMutator.mutateAsync({ taleId, visualDescription, imageBase64, mimeType })

    if (taleImage === null && creatingImage) {
        return <Spinner />
    }

    if (taleImage === null) {
        return <>
            <CostingButton
                onClick={generateNewImage}
                text={"Create Image"}
                costSelector={c => c?.imageCreation}
                disabled={creatingImage}
            />
            <UploadButton onFileUpload={(data, mimeType) => setUploadedImage(data.replace(`data:${mimeType};base64,`, ""), mimeType)} />
        </>
    }

    return (
        <div style={{ width: "max-content", alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div style={{width: "200px", height: "200px"}}>
                <img
                    src={`data:${taleImage.mimeType};base64,${taleImage.base64EncodedData}`}
                    alt={taleImage.altText}
                    style={{width: "100%", height: "100%", objectFit: "contain"}}
                />
            </div>
            <div style={{ marginTop: "8px", width: "max-content" }}>
                <CostingButton
                    onClick={generateNewImage}
                    text={"Regenerate Image"}
                    costSelector={c => c?.imageCreation}
                    disabled={creatingImage || settingImage}
                />
                <UploadButton onFileUpload={(data, mimeType) => setUploadedImage(data.replace(`data:${mimeType};base64,`, ""), mimeType)} />
            </div>
            {creatingImage && <Spinner />}
        </div>
    )
}