import { Quote } from "../../dtos/Quote";


export const allQuotes: Quote[] = [
    {
        text: "Never trust a smiling Dungeon Master",
        author: "thatonerule.com"
    },

    {
        text: "Pen and Paper: Where friendships are forged and dice are feared",
        author: "thatonerule.com"
    },

    {
        text: "A Pen and Paper player's best armor is creativity",
        author: "thatonerule.com"
    },

    {
        text: "You only know true fear once your Dungeon Master asks how many hit points you have left",
        author: "Team Dungeon Tinker"
    },

    {
        text: "Only in Pen and Paper is 'I attack the darkness' an entirely valid strategy",
        author: "thatonerule.com"
    },

    {
        text: "Pen and Paper: where players create plot twists in the Dungeon Master's story",
        author: "Team Dungeon Tinker"
    },
    
    {
        text: "Pen and Paper, the game that makes you try to bargain with dice",
        author: "Team Dungeon Tinker"
    },

    {
        text: "Pen and Paper: where you can slay dragons and still be home in time for dinner",
        author: "thatonerule.com"
    },

    {
        text: "Why don't skeletons fight each other? They don't have the guts for it",
        author: "punspro.com"
    },

    {
        text: "A necromancer's favorite type of investment? A cryptocurrency",
        author: "punspro.com"
    },

    {
        text: "I'm reading a book on anti-gravity in DnD. It's impossible to put down",
        author: "punspro.com"
    },

    {
        text: "What do you call a knight who's afraid to fight? Sir Render",
        author: "punspro.com"
    },

    
    {
        text: "How does a knight communicate with his friends? Through chain mail!",
        author: "punspro.com"
    },

    {
        text: "Wizards are easy to shop for - they love anything that's wand-size-fits-all",
        author: "punspro.com"
    },

    {
        text: "I tried to grab the fog, but I mist",
        author: "punspro.com"
    },

    {
        text: "An invisible wizard walks into a bar, and the bartender says, “Sorry, I can't serve spirits”",
        author: "punspro.com"
    },

    {
        text: "Why did the wizard bring a ladder to the bar? He heard the drinks were on the house",
        author: "punspro.com"
    },

    {
        text: "What's a rogue's favorite game? Hide and sneak",
        author: "Team Dungeon Tinker"
    },

    {
        text: "Why did the cleric break up with the barbarian? There was too much rage in the relationship",
        author: "punspro.com"
    },

    {
        text: "When a cleric is in charge of the music, you know the bass is about to get blessed",
        author: "punspro.com"
    },

    {
        text: "I told my cleric I was feeling lost. They gave me a compass-ionate hug",
        author: "punspro.com"
    },

    {
        text: "Why did the cleric stay at the inn? They wanted to rest in peace",
        author: "punspro.com"
    },

    {
        text: "What's a beholder's favorite app? Eye-Tunes!",
        author: "punspro.com"
    },

    {
        text: "What's a ghost's favorite dessert? I-scream!",
        author: "punspro.com"
    },

    {
        text: "Why are mimics the best at parties? They can really fit in!",
        author: "punspro.com"
    },

    {
        text: "Why did the zombie go to school? He wanted to improve his “dead”ucation!",
        author: "punspro.com"
    },

    {
        text: "How do skeletons communicate? With a tele-bone!",
        author: "punspro.com"
    },
]