import { useState } from "react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { GetBackendClient } from "../../services/BackendClientFactory"
import { useUserToken } from "../token/TokenQuery"
import { imagesKey } from "./ImageQueries"
import { SetImageResource, TaleIdResource } from "../../generated/BackendApiClient"
import { useUserSettingsQuery } from "../user/settings/UserSettingsQueries"
import { invalidateCoins } from "../user/resources/UserResourceMutators"

type CreateTaleImage = { taleId: TaleIdResource, visualDescription: string }
export const useCreateTaleImageMutator = () => {

    const queryClient = useQueryClient()
    const { userSettings } = useUserSettingsQuery()
    const { userToken } = useUserToken()
    const [creating, setCreating] = useState(false)

    const createTaleImageMutator = useMutation({
        mutationFn: async (createTaleImage: CreateTaleImage) => {
            setCreating(true)
            const response = await GetBackendClient(userToken, userSettings).createImage.createImage(createTaleImage.taleId.id, createTaleImage.visualDescription)
            invalidateCoins(queryClient)
            setCreating(false)
            return response.data
        },
        onSuccess: (image, createTaleImage) => queryClient.setQueryData([imagesKey, createTaleImage.taleId.id, createTaleImage.visualDescription], () => image)
    })

    return { createTaleImageMutator, creatingImage: creating }
}

type SetTaleImage = { taleId: TaleIdResource, visualDescription: string, imageBase64: string, mimeType: string }
export const useSetTaleImageMutator = () => {

    const queryClient = useQueryClient()
    const { userSettings } = useUserSettingsQuery()
    const { userToken } = useUserToken()
    const [settingImage, setSettingImage] = useState(false)

    const setTaleImageMutator = useMutation({
        mutationFn: async (setTaleImage: SetTaleImage) => {
            setSettingImage(true)
            const resurce: SetImageResource = {
                imageBase64Data: setTaleImage.imageBase64,
                mimeType: setTaleImage.mimeType,
                visualDescription: setTaleImage.visualDescription
            }
            const response = await GetBackendClient(userToken, userSettings).setImage.setImage(setTaleImage.taleId.id, resurce)
            invalidateCoins(queryClient)
            setSettingImage(false)
            return response.data
        },
        onSuccess: (image, setTaleImage) => queryClient.setQueryData([imagesKey, setTaleImage.taleId.id, setTaleImage.visualDescription], () => image)
    })

    return { setTaleImageMutator, settingImage }
}