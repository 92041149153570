import { FC, useState } from "react"
import './Admin.css'
import { FeatureFlagsComponent } from "../../components/Administration/FeatureFlagsComponent";
import { LlmUsageComponent } from "../../components/Administration/LlmUsageComponent";
import { BetaOverviewComponent } from "../../components/Administration/BetaOverviewComponent";
import { Button } from "react-bootstrap";

export const Admin: FC = () => {

    const [showLlmUsage, setShowLlmUSage] = useState(false)

    return (
        <div className="admin-page">
            <BetaOverviewComponent />
            {
                showLlmUsage ?
                    <LlmUsageComponent /> :
                    <div style={{ marginBlock: 20 }}><Button onClick={() => setShowLlmUSage(true)}>Load Llm Usage</Button></div>
            }
            <FeatureFlagsComponent />
        </div>

    )
}