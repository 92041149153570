import { useQuery } from "@tanstack/react-query";
import { useUserToken } from "../token/TokenQuery";
import { GetBackendClient } from "../../services/BackendClientFactory";
import { TaleIdResource } from "../../generated/BackendApiClient";
import { useUserSettingsQuery } from "../user/settings/UserSettingsQueries";

export const imagesKey = "images"

export const useTaleImageQuery = (taleId: TaleIdResource, visualDescription: string) => {

    const { userToken, isAuthenticated } = useUserToken()
    const { userSettings } = useUserSettingsQuery()
    
    const { data: taleImage } = useQuery({
        queryKey: [imagesKey, taleId.id, visualDescription],
        queryFn: async () => {
            const resp = await GetBackendClient(userToken, userSettings).getImage.getImage(taleId.id, visualDescription)
            return resp.data
        },
        enabled: isAuthenticated,
        retry: 1
    });

    return { taleImage: taleImage ?? null }
}