import { QueryClient } from '@tanstack/react-query'
import { taleKey } from "./TaleQueries"
import { TaleChangedResource, TaleResource } from '../../generated/BackendApiClient'

export const IntegrateUpdatedTale = (queryClient: QueryClient) => (updatedTale: TaleResource) => {
    queryClient.setQueryData([taleKey, updatedTale.id.id], () => updatedTale)
}

export const IntegrateUpdatedTaleByUpdateResource = (queryClient: QueryClient) => (response: TaleChangedResource) => {
    queryClient.setQueryData([taleKey, response.updatedTale.id.id], () => response.updatedTale)
}