import imageCompression from 'browser-image-compression';
import React, { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';

interface UploadButtonProps {
    onFileUpload: (base64File: string, mimeType: string) => void;
}

export const UploadButton: React.FC<UploadButtonProps> = ({ onFileUpload }) => {
    const [showFileInput, setShowFileInput] = useState(false)
    const [uploading, setUploading] = useState(false)

    const handleButtonClick = () => {
        setShowFileInput(true);
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0]
        if (file) {
            setUploading(true)
            const options = {
                maxSizeMB: 1.5,
                maxWidthOrHeight: 1920,
                useWebWorker: true
              }

            const compressedFile = await imageCompression(file, options)

            const reader = new FileReader()
            reader.onloadend = () => {
                if (reader.result) {
                    onFileUpload(reader.result.toString(), file.type)
                }
                setUploading(false)
                setShowFileInput(false)
            };
            reader.readAsDataURL(compressedFile)
        }
    };

    return (
        <div>
            <Button onClick={handleButtonClick}>Upload File</Button>
            {showFileInput && (
                <input type="file" onChange={handleFileChange} accept=".jpg, .jpeg, .png"/>
            )}
            {uploading && (<>Uploading...<Spinner></Spinner></>)}
        </div>
    );
};
