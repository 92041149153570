import { FC, useState } from "react"
import "./QuoteComponent.css"
import { useSingleQuoteQuery } from "../stores/quotes/QuoteQueries"
import { Button, Spinner } from "react-bootstrap"
import { Quote } from "../dtos/Quote"



export const QuoteComponent: FC = () => {
    const [quoteIndex, setQuoteIndex] = useState(Math.round(Math.random() * 100))
    const { quote } = useSingleQuoteQuery(quoteIndex)

    const renderQuote = (quote: Quote) => {
        return (
            <>  
                <div id="quote-title-wrapper">
                    <p className="quote-title">Quote of the day</p>
                <Button className="new-quote-button" onClick={() => setQuoteIndex(i => i + 1)}><img id="reload-icon" alt="reload" src="/img/reload.svg" /></Button>
                </div>
                <q className="quote-text">{quote.text}</q>
                <p className="quote-author">~ {quote.author}</p>

            </>
        )
    }

    return (
        <>
            <div className="quote-container">
                {quote ? renderQuote(quote) : <Spinner />}
            </div>
        </>
    )
}