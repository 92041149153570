import { useParams } from "react-router-dom"
import { CollectRegions, GetNodeByRegionId } from "../dtos/RegionTreeExtensions"
import { useSelectedTale } from "./SelectedTaleHook"

export const useSelectedRegion = () => {
    const { tale } = useSelectedTale()
    const { regionId } = useParams()

    if (tale === undefined) {
        return { tale, selectedRegion: undefined, selectedRegionNode: undefined }
    }

    const regions = CollectRegions(tale.regions)
    const selectedRegion = regions.find(region => region.id.identifier === regionId) ?? tale.regions.root?.region
    const selectedRegionNode = selectedRegion ? GetNodeByRegionId(tale.regions.root, selectedRegion.id.identifier) ?? tale.regions?.root : undefined

    return { tale, selectedRegion, selectedRegionNode }
} 