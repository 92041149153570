import { FC } from 'react';
import './App.css';
import LoginForm from './pages/Login/LoginForm';
import { HeaderMenu } from './components/HeaderMenu';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Forge } from './pages/Forge/Forge';
import { Library } from './pages/Library/Library';
import { Market } from './pages/Market/Market';
import { TalePage } from './pages/TalePage';
import { Tavern } from './pages/Tavern/Tavern';
import { Town } from './pages/Town/Town';
import { useAuthStateQuery } from './stores/token/TokenQuery';
import { TaleCreation } from './pages/Library/TaleCreation';
import { IgnoreBetaTesterRole } from './util/Envs';
import { Admin } from './pages/Admin/Admin';
import { useUserQuery } from './stores/user/user/UserQueries';
import { MageTower } from './pages/MageTower/MageTower';
import { BetaPage } from './pages/RequestBetaAccess/BetaPage';
import { useUserBetaStatusQuery } from './stores/beta/BetaQueries';

export const App: FC = () => {
  const authState = useAuthStateQuery()
  const user = useUserQuery()
  const { userBetaStatus } = useUserBetaStatusQuery()

  if (!authState.allReady || !user || !userBetaStatus) {
    return (
      <div className="App">
        <div className="content" style={{ marginTop: 0 }}>
          <BrowserRouter>
            <HeaderMenu showNavigation={false} />
            <LoginForm AuthState={authState} />
          </BrowserRouter>
        </div>
      </div>
    );
  }

  if (
    authState.userToken === undefined ||
    authState.userSettingsResource === undefined
  ) {
    throw new Error("Invalid Auth State")
  }

  if (!(user.IsBetaTester || IgnoreBetaTesterRole)) {
    return <>
      <BrowserRouter>
        <HeaderMenu showNavigation={false} />
        <BetaPage />
      </BrowserRouter>
    </>
  }

  return (
    <>
      <BrowserRouter>
        <HeaderMenu showNavigation />
        <Routes>
          <Route path="/">
            <Route index element={<Town />} />
            <Route path="forge" element={<Forge />} />
            <Route path="market" element={<Market />} />
            <Route path="library" element={<Library />} />
            <Route path="tavern" element={<Tavern />} />
            <Route path="tale/:taleId/*" element={<TalePage />} />
            <Route path="CreateTale" element={<TaleCreation />} />
            <Route path="MageTower" element={<MageTower />} />
            {user.IsAdmin && <Route path="Admin" element={<Admin />} />}
          </Route>
        </Routes>
        <footer>DT</footer>
      </BrowserRouter>
    </>
  );
}