import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { TaleNavigation } from "../components/TaleNavigation";
import { TaleMetaData } from "../components/TaleVisualizations/TaleMetaData";
import "./TalePage.css"
import { TaleRegionSubPage } from "../components/TaleVisualizations/TaleRegionSubPage";
import { useSelectedTale } from "../services/SelectedTaleHook";
import { TaleRegionCreationSubPage } from "../components/TaleVisualizations/TaleRegionCreationSubPage";

export const TalePage: FC = () => {

    const { tale } = useSelectedTale()

    if (tale === undefined) {
        return <div>Loading Tale... <Spinner /></div>
    }


    const contentPage = () => {

        return (
            <Routes>
                <Route path="/">
                    <Route path="/" element={<TaleMetaData tale={tale} />} />
                    <Route path="/world" element={<TaleMetaData tale={tale} />} />
                    <Route path="/create-region-child/:regionId" element={<TaleRegionCreationSubPage />} />
                    <Route path="/region/:regionId" element={<TaleRegionSubPage />} />
                    <Route path="/regions" element={<TaleRegionCreationSubPage />} />
                </Route>
            </Routes>
        )
    }

    return (
        <div className='mainContainer tale-page'>
            <div style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', height: '100%' }}>
                <TaleNavigation tree={tale.regions} tale={tale}></TaleNavigation>
                <div className="content-page">
                    {contentPage()}
                </div>
            </div>
        </div>
    )
}

