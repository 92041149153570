import { RegionResource, RegionTreeNodeResource, RegionTreeResource } from "../generated/BackendApiClient";


export const CollectRegions = (tree: RegionTreeResource) => {
    if (!tree.root) {
        return []
    }
    return CollectRegionsFromNode(tree.root);
}

export const GetNodeByRegionId = (node: RegionTreeNodeResource, regionId: string): RegionTreeNodeResource | undefined => {
    if (node.region.id.identifier === regionId) {
        return node;
    }

    for (const child of node.children) {
        const result = GetNodeByRegionId(child, regionId);
        if (result) {
            return result;
        }
    }

    return undefined;
}

export const isParentOrSameOfGenerator = (child: RegionTreeNodeResource) => (region: RegionTreeNodeResource): boolean => {
    if (child.region.id.identifier === region.region.id.identifier) {
        return true;
    }

    return region.children.some((n: RegionTreeNodeResource) => isParentOrSameOfGenerator(child)(n));
}

const CollectRegionsFromNode = (node: RegionTreeNodeResource): RegionResource[] => {
    return [node.region, ...node.children.flatMap(child => CollectRegionsFromNode(child))];
}

export type RegionType = 'universe' | 'galaxy' | 'solarsystem' | 'celestialbody' | 'continent' | 'country' | 'area' | 'poi' | 'segment'
export const RegionTypes: RegionType[] = [
    'universe',
    'galaxy',
    'solarsystem',
    'celestialbody',
    'continent',
    'country',
    'area',
    'poi',
    'segment'
];