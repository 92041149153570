import { FC } from "react";
import { useUserBetaStatusQuery } from "../../stores/beta/BetaQueries";
import { RequestBetaAcces } from "./RequestBetaAccess";
import { Spinner } from "react-bootstrap";
import { BetaQueue } from "../BetaQueue/BetaQueue";

export const BetaPage: FC = () => {
    const { userBetaStatus } = useUserBetaStatusQuery()

    if (!userBetaStatus) {
        return <Spinner />
    }

    if (userBetaStatus?.inQueue) {
        return (<BetaQueue />)
    } else {
        return (<RequestBetaAcces />)
    }
}