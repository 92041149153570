import { useMsal, useIsAuthenticated } from "@azure/msal-react"
import { useQueryClient, useQuery } from "@tanstack/react-query"
import { scopes } from "../../dtos/Scopes"
import { AuthenticationResponseResource } from "../../generated/BackendApiClient"
import { GetBackendClient } from "../../services/BackendClientFactory"
import { userAuthResourceKey, userTokenKey } from "./TokenQuery"

export const msTokenKey = "msToken"

export const useMsAuthQuery = () => {
    const queryClient = useQueryClient()
    const { instance } = useMsal()
    const msalIsAuthenticated = useIsAuthenticated()

    const { data: msToken } = useQuery({
        queryKey: [msTokenKey],
        queryFn: async () => {
            const account = instance.getAllAccounts()[0]
            const response = await instance.acquireTokenSilent({
                scopes: scopes,
                account: account,
            })
            return response.accessToken
        },
        enabled: msalIsAuthenticated
    })

    useQuery<AuthenticationResponseResource>({
        queryKey: [userTokenKey, msTokenKey, msToken],
        queryFn: async () => {
            const resp = await GetBackendClient(undefined).microsoftAuthentication.microsoftAuthentication(msToken as string)
            queryClient.setQueryData([userAuthResourceKey], () => resp.data)
            queryClient.invalidateQueries({ queryKey: [userTokenKey], exact: true })
            return resp.data
        },
        enabled: !!msToken,
    })

    return { msToken: msToken }
}
