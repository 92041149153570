import { FC, useState } from "react"
import { Accordion, Button, Spinner } from "react-bootstrap"
import { ProposalType, useProposalQuery } from "../stores/proposals/ProposalQueries"
import { CoinCostResource, ProposalResource, TaleIdResource } from "../generated/BackendApiClient"
import { CostingButton } from "./CostingButton"

type ProposalGeneratorProps = {
    onSelectedProposal: (proposal: ProposalResource) => void,
    costSelection: (costs?: CoinCostResource) => number | undefined
    type: ProposalType,
    taleId: TaleIdResource | undefined,
    queryParams?: any
}

export const ProposalGenerator: FC<ProposalGeneratorProps> = ({ onSelectedProposal, costSelection, type, taleId, queryParams }) => {

    const [seed, setSeed] = useState("")
    const [active, setActive] = useState(false)
    const [id, setId] = useState(Math.random())

    const { proposals } = useProposalQuery(seed, active, type, id, taleId?.id, queryParams)
    const [selectedProposal, setSelectedProposal] = useState<ProposalResource | undefined>(undefined)
    const hasSelected = selectedProposal !== undefined

    const reset = () => {
        setActive(false)
        setId(Math.random())
        setSelectedProposal(undefined)
    }

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 5, padding: 7 }}>
            <textarea
                disabled={active || hasSelected}
                value={seed}
                onChange={e => setSeed(e.target.value)}
                placeholder="detailed description of your idea"
            />
            <CostingButton
                onClick={() => setActive(true)}
                text={"Generate Proposals"}
                costSelector={c => c?.proposalGeneration}
                disabled={active || hasSelected}
            />
            {proposals === undefined && active && <Spinner />}
            {proposals?.slice(0, 3).map((proposal, i) =>
                <div key={proposal.name} style={{ marginTop: 20, display: selectedProposal && selectedProposal.gist !== proposal.gist ? 'none' : undefined }}>
                    <h4>
                        <CostingButton
                            onClick={() => { setSelectedProposal(proposal); onSelectedProposal(proposal) }}
                            text={"Select"}
                            costSelector={costSelection}
                            disabled={hasSelected}
                        />
                        Proposal {i + 1} - {proposal.name}
                    </h4>
                    <Accordion key={proposal.name}>
                        <Accordion.Item eventKey={i.toString()}>
                            <Accordion.Header>{proposal.inOneSentence}</Accordion.Header>
                            <Accordion.Body>{proposal.gist}</Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            )}
            {proposals !== undefined && !hasSelected &&
                <CostingButton
                    onClick={() => setId(Math.random())}
                    text={"Regenerate"}
                    costSelector={c => c?.proposalGeneration}
                    disabled={proposals === undefined}
                />
            }
            {active && !hasSelected && <Button onClick={reset} >Change Seed Text</Button>}
        </div>
    )
}