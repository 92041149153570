import { useSelectedRegion } from "../../services/SelectedRegionHook"
import { RegionComponent } from "../RegionComponent"
import { RegionCreationComponent } from "./RegionCreationComponent"

export const TaleRegionCreationSubPage = () => {

    const { tale, selectedRegion, selectedRegionNode } = useSelectedRegion()

    if (!tale) {
        return <div>Loading...</div>
    }

    return (
        <>
            {selectedRegionNode ? <h2>Creating Sub-Region of {selectedRegion.name}</h2> : <h2>Creating first Region</h2>}
            <RegionCreationComponent parent={selectedRegionNode} taleId={tale.id} />
        </>
    )
}