import { FC, useState } from "react";
import { TaleImageFrame } from "../TaleImageFrame";
import { Button, Card, Spinner } from "react-bootstrap";
import { useRemoveRegionMutator, useUpdateRegionsMutator } from "../../stores/tale/regions/RegionsMutators";
import { ProposalResource, RegionTreeNodeResource, TaleIdResource } from "../../generated/BackendApiClient";
import { HasTalePermission } from "../../util/Permission";
import { ManualPrompt } from "../ManualPrompt";
import { ProposalGenerator } from "../ProposalGenerator";
import { TaleItemView } from "./TaleItemView";
import { TaleCharacterView } from "./TaleCharacterView";
import { TaleSpeciesView } from "./TaleSpeciesView";
import { useCreateCharacterMutator } from "../../stores/tale/characters/CharacterMutators";
import { useCreateSpeciesMutator } from "../../stores/tale/species/SpeciesMutators";
import { useCreateItemMutator } from "../../stores/tale/items/ItemMutators";
import { RegionCreationComponent } from "./RegionCreationComponent";
import "./TaleRegionView.css"
import { Link } from "react-router-dom";
import { taleUriBase } from "../../services/UriBuilder";

interface TaleRegionViewProps {
    node: RegionTreeNodeResource,
    taleId: TaleIdResource
}

export const TaleRegionView: FC<TaleRegionViewProps> = ({ node, taleId }) => {
    const region = node.region
    const { updateRegionsMutator, updatingRegions } = useUpdateRegionsMutator()
    const { removeRegionMutator, removingRegion } = useRemoveRegionMutator()
    const { createCharacterMutator, creatingCharacter } = useCreateCharacterMutator()
    const { createSpeciesMutator, creatingSpecies } = useCreateSpeciesMutator()
    const { createItemMutator, creatingItem } = useCreateItemMutator()

    const [showCreateCharacter, setShowCreateCharacter] = useState(false)
    const [showCreateSpecies, setShowCreateSpecies] = useState(false)
    const [showCreateItem, setShowCreateItem] = useState(false)

    const [editing, setEditing] = useState<boolean>(false)

    const saveOrEditRegion = async () => {
        if (editing) {
            await updateRegionsMutator.mutateAsync({ region, taleId })
        }

        setEditing(!editing)
    }

    const removeRegion = async () => {
        await removeRegionMutator.mutateAsync({ regionId: region.id, taleId })
    }

    const createNewCharacter = async (proposal: ProposalResource) => createCharacterMutator.mutateAsync({ taleId: taleId, regionId: region.id, proposal }).then(_ => setShowCreateCharacter(false))
    const createNewSpecies = async (proposal: ProposalResource) => createSpeciesMutator.mutateAsync({ taleId: taleId, regionId: region.id, proposal }).then(_ => setShowCreateSpecies(false))
    const createNewItem = async (proposal: ProposalResource) => createItemMutator.mutateAsync({ taleId: taleId, regionId: region.id, proposal }).then(_ => setShowCreateItem(false))

    return (
        <div id={'regions_' + region.id.identifier} style={{ display: "flex", flexDirection: "column", margin: "24px 24px 24px 0px" }}>
            {region.id.identifier && <>
                <div style={{ display: "flex", alignItems: "flex-end", marginBottom: "16px" }}>
                    <h2>{region.name} - {region.regionType}</h2>
                    <p style={{ marginBottom: "8px", marginLeft: "12px" }}>Region</p>
                    <Button onClick={removeRegion} disabled={removingRegion}>x</Button>
                </div>

                <div style={{ display: "flex", flexDirection: "row", position: "relative" }}>
                    <div>
                        <div style={{ float: "right", height: "max-content", alignItems: "center", display: "flex", flexDirection: "column", margin: "0px 0px 12px 12px", backgroundColor: "#b93f45" }}>
                            <div style={{ margin: "8px", width: "max-content" }}>
                                <TaleImageFrame taleId={taleId} visualDescription={region.visualDescription} />
                            </div>
                        </div>
                        <div onBlur={(event) => region.description = event.currentTarget.innerText} suppressContentEditableWarning={true}
                            contentEditable={editing}>
                            {region.description}
                        </div>
                        {HasTalePermission(taleId, "EditTale") ? <Button disabled={updatingRegions} onClick={saveOrEditRegion}>{editing ? "Save" : "Edit Region"}</Button> : <></>}
                        {updatingRegions && <Spinner />}
                    </div>
                </div>
            </>}

            <div id={"species_" + region.id.identifier} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: 40 }}>
                <h2>Species</h2>
                <div className="vertical-scroll-container">
                    {region.species.map(species => (
                        <Card key={species.id!.identifier} >
                            <Card.Header><Card.Title>{species.name}</Card.Title></Card.Header>
                            <Card.Body>
                                <Card.Img src="/dummyImages/Dummy1.png" />
                            </Card.Body>
                        </Card>)
                    )}
                </div>
                {region.species.map(species => <TaleSpeciesView key={species.id!.identifier} species={species} taleId={taleId} region={region} />)}
                {HasTalePermission(taleId, "EditTale") ? <Button style={{ margin: "12px 0px 12px 0px", maxWidth: "30%" }} disabled={creatingSpecies || showCreateSpecies} onClick={() => setShowCreateSpecies(true)}>Create new Species</Button> : <></>}
                {showCreateSpecies && <ManualPrompt onSelectedProposal={createNewSpecies} costSelection={c => c?.speciesCreation} />}
                {showCreateSpecies && <h3>Generate Proposals</h3>}
                {showCreateSpecies && <ProposalGenerator taleId={taleId} onSelectedProposal={createNewSpecies} costSelection={c => c?.speciesCreation} type="Species" />}
                {creatingSpecies ? <div>Creating Species... <Spinner /></div> : <></>}
            </div>
            <div id={"characters_" + region.id.identifier} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: 40 }}>
                <h2>Characters</h2>
                {region.characters.map(character => <TaleCharacterView key={character.id.identifier} character={character} taleId={taleId} region={region} />)}
                {HasTalePermission(taleId, "EditTale") ? <Button style={{ margin: "12px 0px 12px 0px", maxWidth: "30%" }} disabled={creatingCharacter || showCreateCharacter} onClick={() => setShowCreateCharacter(true)}>Create new Character</Button> : <></>}
                {showCreateCharacter && <ManualPrompt onSelectedProposal={createNewCharacter} costSelection={c => c?.characterCreation} />}
                {showCreateCharacter && <h3>Generate Proposals</h3>}
                {showCreateCharacter && <ProposalGenerator taleId={taleId} onSelectedProposal={createNewCharacter} costSelection={c => c?.characterCreation} type="Character" />}
                {creatingCharacter ? <div>Creating Character... <Spinner /></div> : <></>}
            </div>
            <div id={"items_" + region.id.identifier} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: 40 }}>
                <h2>Items</h2>
                {region.items.map(item => <TaleItemView key={item.id.identifier} item={item} taleId={taleId} region={region} />)}
                {HasTalePermission(taleId, "EditTale") ? <Button style={{ margin: "12px 0px 12px 0px", maxWidth: "30%" }} disabled={creatingItem || showCreateItem} onClick={() => setShowCreateItem(true)}>Create new Item</Button> : <></>}
                {showCreateItem && <ManualPrompt onSelectedProposal={createNewItem} costSelection={c => c?.itemCreation} />}
                {showCreateItem && <h3>Generate Proposals</h3>}
                {showCreateItem && <ProposalGenerator taleId={taleId} onSelectedProposal={createNewItem} costSelection={c => c?.itemCreation} type="Item" />}
                {creatingItem ? <div>Creating Item... <Spinner /></div> : <></>}
            </div>

            <div id={"regions_" + region.id.identifier} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: 40 }}>
                <h2>Sub-Regions</h2>
                {node.children.map(subregion => <h3>{subregion.region.name}</h3>)}
                <Link to={taleUriBase(taleId) + '/create-region-child/' + node.region.id.identifier}>Create SubRegion</Link>
            </div>
        </div>
    )
}