import { FC } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useLogoutMutator } from "../stores/token/TokenMutators";
import { useUserSettingsQuery } from "../stores/user/settings/UserSettingsQueries";
import { useUserQuery } from "../stores/user/user/UserQueries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut, faUser } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useUserResourcesQuery } from "../stores/user/resources/UserResourcesQueries";

type HeaderMenuProps = {
  showNavigation: boolean;
};

export const HeaderMenu: FC<HeaderMenuProps> = ({ showNavigation }) => {
  const nav = useNavigate();
  const { userSettings } = useUserSettingsQuery();
  const { userResources } = useUserResourcesQuery();
  const user = useUserQuery();
  const { logoutMutation } = useLogoutMutator();

  const loggedInView = (
    <>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <Link to="/">Town Square</Link>
        </Nav>
        <Nav className="me-auto">
          <Link to="/forge">Forge</Link>
        </Nav>
        <Nav className="me-auto">
          <Link to="/market">Market</Link>
        </Nav>
        <Nav className="me-auto">
          <Link to="/library">Library</Link>
        </Nav>
        <Nav className="me-auto">
          <Link to="/tavern">Tavern</Link>
        </Nav>
        <Nav className="me-auto">
          <Link to="/MageTower">Mages Tower</Link>
        </Nav>
        {user?.IsAdmin && (
          <Nav className="me-auto">
            <Link to="/admin">Admin</Link>
          </Nav>
        )}
      </Navbar.Collapse>
      <Navbar.Collapse className="justify-content-end">
        <Navbar.Text onClick={() => nav("/market")} style={{ marginRight: 10 }}>
          {userResources?.coins} 🪙
        </Navbar.Text>
        <Navbar.Text onClick={() => nav("/MageTower")}>
          <FontAwesomeIcon icon={faUser} style={{ marginRight: 10 }} />
          {userSettings?.userName}
        </Navbar.Text>
        <Navbar.Text onClick={() => logoutMutation.mutate()}>
          <FontAwesomeIcon icon={faSignOut} style={{ marginInline: 10 }} />
          Logout
        </Navbar.Text>
      </Navbar.Collapse>
    </>
  );

  return (
    <header>
      <Navbar expand="lg" style={{ backgroundColor: "var(--bs-primary)" }}>
        <Container>
          <Link to="/">
            <Navbar.Brand
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginRight: 50,
              }}
            >
              <img
                alt="main logo"
                src="/img/LogoWithGlow.svg"
                width="60"
                height="60"
                className="d-inline-block align-top"
              />
              <span
                style={{ color: "white", marginLeft: 20, fontWeight: "bold" }}
              >
                Dungeon
                <br />
                Tinker
              </span>
            </Navbar.Brand>
          </Link>
          {showNavigation ? loggedInView : <></>}
          {!showNavigation && userSettings?.userName ? (
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text>
                <FontAwesomeIcon icon={faUser} style={{ marginRight: 10 }} />
                {userSettings?.userName}
              </Navbar.Text>
              <Navbar.Text onClick={() => logoutMutation.mutate()}>
                <FontAwesomeIcon icon={faSignOut} style={{ marginInline: 10 }} />
                Logout
              </Navbar.Text>
            </Navbar.Collapse>
          ) : <></>}
        </Container>
      </Navbar>
    </header>
  );
};