import { useQuery, useQueryClient } from "@tanstack/react-query"
import { GetBackendClient } from "../../services/BackendClientFactory"
import { useUserToken } from "../token/TokenQuery"
import { useUserSettingsQuery } from "../user/settings/UserSettingsQueries"
import { invalidateCoins } from "../user/resources/UserResourceMutators"

const proposalQueryKey = "proposal"

export type ProposalType = "Tale" | "Region" | "Character" | "Species" | "Item" | "Encounter"

export const useProposalQuery = (seed: string, active: boolean, type: ProposalType, id: number, taleId?: string, queryParams?: any) => {

    const { userToken, isAuthenticated } = useUserToken()
    const { userSettings } = useUserSettingsQuery()
    const queryClient = useQueryClient()

    const { data } = useQuery({
        queryKey: [proposalQueryKey, type, seed, id],
        enabled: active && isAuthenticated,
        staleTime: Infinity,
        queryFn: async () => {
            const client = GetBackendClient(userToken, userSettings)
            invalidateCoins(queryClient)
            switch(type){
                case "Tale":
                    return (await client.proposals.createTaleProposals(seed)).data
                case "Region":
                    return (await client.proposals.createRegionProposals(taleId!, queryParams, seed)).data
                case "Character":
                    return (await client.proposals.createCharacterProposals(taleId!, seed)).data
                case "Species":
                    return (await client.proposals.createSpeciesProposals(taleId!, seed)).data
                case "Item":
                    return (await client.proposals.createItemProposals(taleId!, seed)).data
                case "Encounter":
                    return (await client.proposals.createEncounterProposals(taleId!, queryParams, seed)).data
            }
        }
    })

    return { proposals: data }
}