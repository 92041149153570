import { FC } from "react";
import { RegionTreeNodeResource, TaleIdResource } from "../generated/BackendApiClient";
import { TaleRegionView } from "./TaleVisualizations/TaleRegionView";

type RegionComponentProps = {
    node: RegionTreeNodeResource
    taleId: TaleIdResource
}

export const RegionComponent: FC<RegionComponentProps> = ({ node, taleId }) => {

    return (
        <div>
            <TaleRegionView key={node.region.name} node={node} taleId={taleId} />
        </div>
    )
}