import { FC } from "react"
import { NavRegion } from "./NavRegion"
import { RegionTreeResource, TaleResource } from "../generated/BackendApiClient"
import "./TaleNavigation.css"
import { isParentOrSameOfGenerator } from "../dtos/RegionTreeExtensions"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGlobe, faLocationPin } from "@fortawesome/free-solid-svg-icons"
import { useSelectedRegion } from "../services/SelectedRegionHook"

type TaleNavigationProps = {
    tree: RegionTreeResource,
    tale: TaleResource
}

export const TaleNavigation: FC<TaleNavigationProps> = ({ tree }) => {

    const { tale, selectedRegionNode } = useSelectedRegion()

    const getContent = () => {

        if (!tale) {
            return <div>Loading...</div>
        }

        return (
            <>
                <div className="nav-region">
                    <Link to={`world`}><h3><FontAwesomeIcon icon={faGlobe} />{" " + tale.world.name}</h3></Link>
                </div>
                <div className="nav-region">
                    <Link to={'regions'}><h3><FontAwesomeIcon icon={faLocationPin} /> Regions</h3></Link>
                    {tree.root && <NavRegion node={tree.root} isParentOfSelected={selectedRegionNode ? isParentOrSameOfGenerator(selectedRegionNode) : () => false} />}
                </div>
            </>
        )
    }

    return (
        <div className="TaleNavigation">
            {getContent()}
        </div>
    )
}