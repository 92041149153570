import { FC } from "react"
import { RegionComponent } from "../RegionComponent"
import { useSelectedRegion } from "../../services/SelectedRegionHook"


export const TaleRegionSubPage: FC = () => {

    const { tale, selectedRegionNode } = useSelectedRegion()

    return (
        selectedRegionNode ? <RegionComponent node={selectedRegionNode} taleId={tale.id} /> : <p>Not found</p>
    )
}