import { useState } from "react"
import { useMutation } from "@tanstack/react-query"
import { GetBackendClient } from "../../../services/BackendClientFactory"
import { useUserToken } from "../../token/TokenQuery"
import { IntegrateUpdatedTale, IntegrateUpdatedTaleByUpdateResource } from "../TaleIntegrations"
import { useQueryClient } from '@tanstack/react-query'
import { IdResource, ProposalResource, Region, TaleIdResource } from "../../../generated/BackendApiClient"
import { useUserSettingsQuery } from "../../user/settings/UserSettingsQueries"
import { invalidateCoins } from "../../user/resources/UserResourceMutators"

type RegionCreationArgs = {
    taleId: TaleIdResource,
    proposal: ProposalResource,
    regionType: string,
    parentId: string,
}

export const useCreateRegionsMutator = () => {

    const { userToken } = useUserToken()
    const [creating, setCreating] = useState(false)
    const { userSettings } = useUserSettingsQuery()
    const queryClient = useQueryClient()

    const createRegionsMutator = useMutation({
        mutationFn: async (creationArgs: RegionCreationArgs) => {
            setCreating(true)
            const updatedTale = await GetBackendClient(userToken, userSettings).tales.createRegions(
                creationArgs.taleId.id,
                {
                    parentId: creationArgs.parentId,
                    regionType: creationArgs.regionType,
                },
                creationArgs.proposal
            )
            invalidateCoins(queryClient)
            setCreating(false)
            return updatedTale.data
        },
        onSuccess: IntegrateUpdatedTaleByUpdateResource(queryClient)
    })

    return { createRegionsMutator, creatingRegions: creating }
}

type UpdateRegion = { taleId: TaleIdResource, region: Region }
export const useUpdateRegionsMutator = () => {

    const queryClient = useQueryClient()
    const { userToken } = useUserToken()
    const { userSettings } = useUserSettingsQuery()
    const [updating, setUpdating] = useState(false)

    const updateRegionsMutator = useMutation({
        mutationFn: async (updateRegion: UpdateRegion) => {
            setUpdating(true)
            const updatedTale = await GetBackendClient(userToken, userSettings).updateRegion.updateRegion(updateRegion.taleId.id, updateRegion.region)
            setUpdating(false)
            return updatedTale.data
        },
        onSuccess: IntegrateUpdatedTale(queryClient)
    })

    return { updateRegionsMutator, updatingRegions: updating }
}

type RemoveRegion = { regionId: IdResource, taleId: TaleIdResource }
export const useRemoveRegionMutator = () => {

    const queryClient = useQueryClient()
    const { userToken } = useUserToken()
    const { userSettings } = useUserSettingsQuery()
    const [removing, setRemoving] = useState(false)

    const removeRegionMutator = useMutation({
        mutationFn: async (removeRegion: RemoveRegion) => {
            setRemoving(true)
            const updatedTale = await GetBackendClient(userToken, userSettings).removeRegion.removeRegion(removeRegion.taleId.id, removeRegion.regionId)
            setRemoving(false)
            return updatedTale.data
        },
        onSuccess: IntegrateUpdatedTale(queryClient)
    })

    return { removeRegionMutator, removingRegion: removing }
}