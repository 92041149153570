import { useQuery } from "@tanstack/react-query"
import { allQuotes } from "./QuotesData"
import { Quote } from "../../dtos/Quote"
import { useState } from "react"

const quoteQueryKey = "quotes"
export const useQuotesQuery = () => {
    const { data } = useQuery({
        queryKey: [quoteQueryKey],
        queryFn:  () => {
            return allQuotes
        }
    })
    if (data !== undefined) {
        shuffleArray(data)
    }
    return { quotes: data }
}

export const useSingleQuoteQuery = (quoteIndex: number) => {
    const {quotes} = useQuotesQuery()
    return {quote: quotes ? quotes[quoteIndex % quotes.length] : undefined}
}

function shuffleArray(array: Quote[]) {
    for (var i = array.length - 1; i >= 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
}