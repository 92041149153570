import { useState } from "react"
import { useUserToken } from "../../token/TokenQuery"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { GetBackendClient } from "../../../services/BackendClientFactory"
import { IntegrateUpdatedTale } from "../TaleIntegrations"
import { SpeciesResource, ProposalResource, TaleIdResource, IdResource } from "../../../generated/BackendApiClient"
import { useUserSettingsQuery } from "../../user/settings/UserSettingsQueries"
import { invalidateCoins } from "../../user/resources/UserResourceMutators"

type SpeciesCreationArgs = {
    taleId: TaleIdResource,
    regionId: IdResource,
    proposal: ProposalResource
}

export const useCreateSpeciesMutator = () => {

    const queryClient = useQueryClient()
    const { userToken } = useUserToken()
    const { userSettings } = useUserSettingsQuery()
    const [creating, setCreating] = useState(false)

    const createSpeciesMutator = useMutation({
        mutationFn: async (args: SpeciesCreationArgs) => {
            setCreating(true)
            const updatedTale = await GetBackendClient(userToken, userSettings).createSpecies.createSpecies(args.taleId.id, args.regionId.identifier, args.proposal)
            invalidateCoins(queryClient)
            setCreating(false)
            return updatedTale.data
        },
        onSuccess: IntegrateUpdatedTale(queryClient)
    })

    return { createSpeciesMutator, creatingSpecies: creating }
}

type UpdateSpecies = { species: SpeciesResource, taleId: TaleIdResource, regionId: IdResource }
export const useUpdateSpeciesMutator = () => {

    const queryClient = useQueryClient()
    const { userToken } = useUserToken()
    const { userSettings } = useUserSettingsQuery()
    const [updating, setUpdating] = useState(false)

    const updateSpeciesMutator = useMutation({
        mutationFn: async (updateSpecies: UpdateSpecies) => {
            setUpdating(true)
            const updatedTale = await GetBackendClient(userToken, userSettings).updateSpecies.updateSpecies(updateSpecies.taleId.id, updateSpecies.regionId.identifier, updateSpecies.species)
            setUpdating(false)
            return updatedTale.data
        },
        onSuccess: IntegrateUpdatedTale(queryClient)
    })

    return { updateSpeciesMutator, updatingSpecies: updating }
}

type RemoveSpecies = { speciesId: IdResource, taleId: TaleIdResource, regionId: IdResource }
export const useRemoveSpeciesMutator = () => {

    const queryClient = useQueryClient()
    const { userToken } = useUserToken()
    const { userSettings } = useUserSettingsQuery()
    const [removing, setRemoving] = useState(false)

    const removeSpeciesMutator = useMutation({
        mutationFn: async (removeSpecies: RemoveSpecies) => {
            setRemoving(true)
            const updatedTale = await GetBackendClient(userToken, userSettings).removeSpecies.removeSpecies(removeSpecies.taleId.id, removeSpecies.regionId.identifier, removeSpecies.speciesId)
            setRemoving(false)
            return updatedTale.data
        },
        onSuccess: IntegrateUpdatedTale(queryClient)
    })

    return { removeSpeciesMutator, removingSpecies: removing }
}