import { HttpResponse, http, passthrough } from 'msw';
import { setupWorker } from 'msw/browser'
import { handlers } from "../generated/handlers.js";
import { AuthenticationResponseResource, UserSettingsResource } from "../generated/BackendApiClient";
import { API_BASEURL } from '../util/Envs';
import { ImageToBase64 } from '../util/ImageUtil';
import { delay } from '../util/Delay';
import { AuthState } from '../dtos/AuthState';

export const DummyToken = "dummyToken"

export const DefaultSettings: UserSettingsResource = { userName: "Max Mustermann", openAiModelType: "Speed", preferredImageStyle: "fantasy style", culture: "en-US" }
export const DummyAuthenticationResponseResource: AuthenticationResponseResource = { userToken: DummyToken, userSettingsResource: DefaultSettings }
export const DummyAuthenticationFailedResponseResource: AuthenticationResponseResource = { errorMessage: 'Dummy failed' }
export const DummyAuthState: AuthState = { userToken: DummyToken, allReady: true, userTokenError: undefined, userSettingsResource: DefaultSettings, isAuthenticated: true }

const frontendHandlers = [
    http.all('/*', passthrough)
]

const miscHandlers = [
    http.all('chrome-extension://*', passthrough),
    http.all('https://login.microsoftonline.com/*', passthrough)
]

const backendOverrideHandlers = [
    http.post(API_BASEURL + '/MicrosoftAuthentication', () => {
        return HttpResponse.json(DummyAuthenticationResponseResource)
    }),
    http.post(API_BASEURL + '/GoogleAuthentication', () => {
        return HttpResponse.json(DummyAuthenticationResponseResource)
    }),
    http.post(API_BASEURL + '/CreateImage/*', async () => {
        return HttpResponse.json(await getRandomImageResponse())
    }),
    http.get(API_BASEURL + '/GetUserSettings', async () => {
        return HttpResponse.json(DefaultSettings)
    }),
    http.post(API_BASEURL + '/UpdateSettings', async () => {
        return HttpResponse.json(DefaultSettings)
    }),
    http.post(API_BASEURL + '/GetImage/*', async () => {

        if (Math.random() > 0.3) {
            return HttpResponse.json(await getRandomImageResponse())
        }

        return HttpResponse.json(null, { status: 404 })
    }),
    http.post(API_BASEURL + '/ApplyForBeta*', async () => {
        await delay(2000)
        return HttpResponse.json(null, { status: 200 })
    }),
]

const getRandomImageResponse = async () => {
    const dummyImageNames = [1, 2, 3, 4, 5].map(i => "Dummy" + i + ".png")
    const randomImageName = dummyImageNames[Math.floor(Math.random() * 5)]

    var imageData = await ImageToBase64(process.env.PUBLIC_URL + '/dummyImages/' + randomImageName)
    return { base64EncodedData: imageData, altText: 'dummy image' }
}

export const apiMockWorker = setupWorker(...[
    ...frontendHandlers,
    ...miscHandlers,
    ...backendOverrideHandlers,
    ...handlers
])